import {
  ReactNode,
  ChangeEvent,
  KeyboardEvent,
  Ref,
  HTMLInputTypeAttribute,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import colours from '@common/colours';
import { Message } from '@nextTypes/translations';

type InputLabelType = {
  children: ReactNode;
  htmlFor?: string;
  hasError?: boolean;
  colour?: string;
};

const InputLabel = ({
  children,
  htmlFor,
  hasError,
  colour,
}: InputLabelType): JSX.Element => (
  <>
    <label
      htmlFor={htmlFor}
      className={`font-cera text-[16px] font-normal leading-[20px] tracking-[-0.22px] text-blue ${
        hasError ? 'text-system-error' : ''
      }`}
      style={{ color: colour }}
    >
      {children}
    </label>
    <style jsx>{`
      label {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: -0.29px;
        text-align: left;
        font-weight: 400;
      }

      label > :global(strong) {
        font-weight: 900;
      }
    `}</style>
  </>
);

// This structure allows us to use the <TextInput> component with or
// without react-hook-form. Either allowing react-hook-form to control it,
// or it to be controlled within the component with `value` and `onChange`
type CommonTextInputType = {
  id?: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  autoFocus: boolean;
  required: boolean;
  inputColor?: string;
  onBlur?: () => void;
  disableIncrement: boolean;
  autoComplete?: string;
  min?: string;
  max?: string;
  dataCy?: string;
};

type TextInputType = CommonTextInputType & {
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef?: (forwardRef: HTMLInputElement) => Ref<HTMLInputElement> | void;
  name: string;
};

type TextInputReactHookFormType = CommonTextInputType & {
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef: UseFormRegisterReturn;
  name?: string;
};

const TextInput = ({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  onKeyDown,
  hasError,
  autoFocus,
  required,
  onBlur,
  inputColor,
  disableIncrement,
  forwardRef,
  autoComplete,
  min,
  max,
  dataCy,
}: TextInputType | TextInputReactHookFormType): JSX.Element => (
  <>
    <input
      id={id}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      required={required}
      className={`block w-full rounded border-[2px] border-blue-100 p-3 font-cera text-[16px] font-normal leading-[20px] tracking-[-0.22px] text-blue focus:border-blue focus:outline-none ${
        hasError ? '!border-system-error' : ''
      }`}
      onBlur={onBlur}
      {...forwardRef}
      autoComplete={autoComplete}
      min={min}
      max={max}
      data-cy={dataCy}
      style={{
        color: inputColor,
      }}
    />
    <style jsx>{`
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        ${disableIncrement
          ? `
        -webkit-appearance: none;
        margin: 0;
        `
          : ''}
      }
    `}</style>
  </>
);

TextInput.defaultProps = {
  autoFocus: false,
  required: false,
  disableIncrement: false,
  autoComplete: undefined,
};

// This structure allows us to use the <SelectInput> component with or
// without react-hook-form. Either allowing react-hook-form to control it,
// or it to be controlled within the component with `value` and `onChange`
type CommonSelectInputType = {
  id?: string;
  options: { value: string; label: string; disabled?: boolean }[];
  placeholder?: string;
  hasError?: boolean;
  autoFocus: boolean;
  required: boolean;
};

type SelectInputType = CommonSelectInputType & {
  value: string | undefined;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  forwardRef?: (ref: HTMLSelectElement) => Ref<HTMLSelectElement> | void;
  name: string;
};

type SelectInputReactHookFormType = CommonSelectInputType & {
  value?: string | undefined;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  forwardRef: UseFormRegisterReturn;
  name?: string;
};

const SelectInput = ({
  id,
  name,
  value,
  options,
  placeholder,
  onChange,
  autoFocus,
  required,
  hasError,
  forwardRef,
}: SelectInputType | SelectInputReactHookFormType): JSX.Element => (
  <select
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    autoFocus={autoFocus}
    required={required}
    className={`m-0 block h-[56px] w-full rounded border-[2px] border-dotted border-blue-400 bg-white px-3 py-1 font-cera text-[16px] font-normal leading-[20px] tracking-[-0.22px] text-blue focus:border-solid focus:border-blue focus:outline-none ${
      hasError ? '!border-system-error' : ''
    }`}
    {...forwardRef}
  >
    {placeholder && (
      <option style={{ display: 'none' }} value="">
        {placeholder}
      </option>
    )}
    {options.map(option => (
      <option
        value={option.value}
        key={option.value.toString()}
        disabled={option.disabled}
      >
        {option.label}
      </option>
    ))}
  </select>
);

SelectInput.defaultProps = {
  autoFocus: false,
  required: false,
};

// This structure allows us to use the <Checkbox> component with or
// without react-hook-form. Either allowing react-hook-form to control it,
// or it to be controlled within the component with `value` and `onChange`
type CommonCheckboxType = {
  children: ReactNode;
  hasError?: boolean;
  required: boolean;
  id?: string;
  checked?: boolean;
  colour?: string;
  dataCy?: string;
};

type CheckboxType = CommonCheckboxType & {
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef?: (ref: HTMLInputElement) => Ref<HTMLInputElement> | void;
  name: string;
};

type CheckboxReactHookFormType = CommonCheckboxType & {
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef: UseFormRegisterReturn;
  name?: string;
};

const Checkbox = ({
  children,
  name,
  value,
  onChange,
  hasError,
  required,
  id,
  forwardRef,
  checked,
  colour,
  dataCy,
}: CheckboxType | CheckboxReactHookFormType): JSX.Element => (
  <>
    <label
      className={
        hasError ? 'cursor-pointer text-system-error' : 'cursor-pointer'
      }
    >
      <div>
        <input
          type="checkbox"
          className="form-checkbox my-auto rounded border-[1.5px] border-solid border-dawn-blue text-dawn-blue !shadow !ring-4 !ring-dawn-blue-200 !ring-offset-0"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          id={id}
          {...forwardRef}
          checked={checked}
          data-cy={dataCy || name}
        />
        {children}
      </div>
    </label>
    <style jsx>{`
      div {
        display: block;
        min-height: 20px;
        padding-left: 20px;
      }

      input {
        float: left;
        margin-left: -20px;
        margin-top: 3px;
      }

      label {
        display: inline-block;
        color: ${colour};
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: -0.29px;
        text-align: left;
        font-weight: 400;
      }

      label > :global(strong) {
        font-weight: 900;
      }
    `}</style>
  </>
);

Checkbox.defaultProps = {
  required: false,
  colour: colours.snBlack,
};

type ErrorMessageType = {
  errorMessage: Message | null | undefined;
  // Used for Cypress tests
  dataCy?: string;
};

const ErrorMessage = ({
  errorMessage,
  dataCy,
}: ErrorMessageType): JSX.Element | null =>
  errorMessage ? (
    <div className="mt-3 flex items-center justify-center">
      <div className="flex h-[21px] w-[21px] min-w-[21px] items-center justify-center rounded-[50%] bg-system-error text-[16px] font-medium leading-none text-blue">
        !
      </div>
      <div className="ml-2 font-cera text-[16px] font-normal leading-[17px] tracking-[-0.22px] text-system-error">
        <span data-cy={dataCy}>{errorMessage}</span>
      </div>
    </div>
  ) : null;

export { InputLabel, TextInput, SelectInput, Checkbox, ErrorMessage };
